<template>
  <b-popover
    :target="target"
    triggers="focus"
    placement="top"
    @show="getData"
    boundary="viewport"
  >
    <template #title>
      <span>{{ title }}</span>
    </template>
    <b-row class="justify-content-md-center">
      <b-spinner v-if="loading" big />
    </b-row>
    <b-row>
      <template v-if="name">
        <b-col md="12">
          <b>{{ name }}</b>
        </b-col>
      </template>
      <template v-if="email">
        <b-col md="12">
          <a :href="'mailto:' + email">
            <b>{{ email }}</b>
          </a>
        </b-col>
      </template>
      <template v-if="consultant_role">
        <b-col md="12">
          <b>{{ consultant_role }}</b>
        </b-col>
      </template>
      <template v-if="phone">
        <b-col md="12">
          <b>{{ phone | formatPhone }}</b>
        </b-col>
      </template>
      <b-col md="12" v-if="!loading && (showDetailUrl || showWhastapp)">
        <br /><br />
        <template v-if="showDetailUrl && routeDetails">
          <b-button
            style="float: left"
            size="sm"
            variant="outline-primary"
            @click.prevent="goToDetail"
          >
            Detalhes
          </b-button>
        </template>
        <template v-if="showWhastapp && phone">
          <b-button
            :style="{ 'float: right;': showDetailUrl }"
            size="sm"
            variant="outline-primary"
            @click.prevent="goToWhatsapp"
          >
            <feather-icon icon="MessageCircleIcon" class="mr-50" />
            Whatsapp
          </b-button>
        </template>
      </b-col>
    </b-row>
  </b-popover>
</template>

<script>
import BCardCode from "@core/components/b-card-code";
import {
  BButton,
  VBPopover,
  BPopover,
  BCardText,
  BButtonGroup,
  BCol,
  BRow,
  BSpinner,
} from "bootstrap-vue";
import { mapActions } from "vuex";
import Ripple from "vue-ripple-directive";
import * as types from "../store/types";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import { useToast } from "vue-toastification/composition";
import * as customerTypes from "@/constants/customers_types";

export default {
  components: {
    BCardCode,
    BButton,
    BCardText,
    BPopover,
    BButtonGroup,
    BCol,
    BRow,
    BSpinner,
  },
  directives: {
    "b-popover": VBPopover,
    Ripple,
  },
  props: {
    target: {
      type: String,
    },
    type: {
      type: String,
      default: "",
    },
    id: {
      type: Number,
      default: undefined,
    },
    showDetailUrl: {
      type: Boolean,
      default: undefined,
    },
    showWhastapp: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return { toast: useToast() };
  },
  data() {
    return {
      loading: true,
      title: undefined,
      name: undefined,
      email: undefined,
      consultant_role: undefined,
      phone: undefined,
      routeDetails: undefined,
    };
  },
  methods: {
    ...mapActions({
      getCustomer: types.GET_CUSTOMER,
      getConsultant: types.GET_CONSULTANT,
      getCompanyAgent: types.GET_COMPANY_AGENT,
    }),
    getData() {
      if (this.id && this.type == "customer") {
        this.getCustomer(this.id)
          .then((response) => {
            const { id, name, email, cell_phone, customer_type_id } =
              response.data;
            this.title = "Cliente";
            this.name = name;
            this.email = email;
            this.phone = cell_phone;
            if (customer_type_id === customerTypes.CUSTOMER) {
              this.routeDetails = this.$router.resolve({
                name: "customer-profile",
                params: { id },
              });
            } else {
              this.routeDetails = this.$router.resolve({
                name: "lead-profile",
                params: { id },
              });
            }
            this.loading = false;
          })
          .catch(() => {
            this.toast({
              component: ToastificationContent,
              props: {
                title: "Oops!",
                text: "Ocorreu um erro ao recuperar os dados do cliente. Entre em contato com o setor de TI.",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          });
      } else if (this.id && this.type == "consultant") {
        this.getConsultant(this.id)
          .then((response) => {
            const { id, name, cell_phone, role } = response.data;
            this.title = "PN";
            this.name = name;
            this.phone = cell_phone;
            this.consultant_role = role.name;
            this.routeDetails = this.$router.resolve({
              name: "consultant-details-summary",
              params: { id },
            });
            this.loading = false;
          })
          .catch(() => {
            this.toast({
              component: ToastificationContent,
              props: {
                title: "Oops!",
                text: "Ocorreu um erro os dados do consultor. Entre em contato com o setor de TI.",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          });
      } else if (this.id && this.type == "company_agent") {
        this.getCompanyAgent(this.id)
          .then((response) => {
            const { id, name, email, phone, type } = response.data;
            this.title = "Representante " + type;
            this.name = name;
            this.email = email;
            this.phone = phone;
            this.loading = false;
          })
          .catch(() => {
            this.toast({
              component: ToastificationContent,
              props: {
                title: "Oops!",
                text: "Ocorreu um erro os dados do representante. Entre em contato com o setor de TI.",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          });
      }
    },
    goToDetail() {
      setTimeout(() => {
        window.open(this.routeDetails.href, "_blank");
      });
    },
    goToWhatsapp() {
      window.open("https://wa.me/" + this.phone, "_blank").focus();
    },
  },
};
</script>
