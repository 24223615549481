export const PNH_ROLE_ID = 10;
export const PNC_ROLE_ID = 9;
export const PNP_ROLE_ID = 8;
export const PNF_ROLE_ID = 7;
export const PNM1_ROLE_ID = 6;
export const PNM2_ROLE_ID = 5;
export const PNM3_ROLE_ID = 4;
export const PNS_ROLE_ID = 3;
export const FF_ROLE_ID = 2;
export const EMPRESA_ROLE_ID = 1;

export const PNP1_ROLE_ID = 11;
export const PNP2_ROLE_ID = 12;
export const PNP3_ROLE_ID = 13;

export const EXECUTIVE_ROLES = [PNP1_ROLE_ID, PNP2_ROLE_ID, PNP3_ROLE_ID];

export const PNP_OR_HIGHER_ROLES = [
  PNP_ROLE_ID,
  PNF_ROLE_ID,
  PNM1_ROLE_ID,
  PNM2_ROLE_ID,
  PNM3_ROLE_ID,
  PNS_ROLE_ID,
  FF_ROLE_ID,
  EMPRESA_ROLE_ID,
  PNP1_ROLE_ID,
  PNP2_ROLE_ID,
  PNP3_ROLE_ID,
];

export function isPnpOrHigher(consultant_role) {
  return consultant_role && PNP_OR_HIGHER_ROLES.includes(consultant_role);
}

export const CUSTOMER_USER_ROLE_ID = 5;
export const PNH_USER_ROLE_ID = 6;
export const PNP_USER_ROLE_ID = 7;
export const EXTERNAL_USER_ROLE_ID = 22;
export const PNC_USER_ROLE_ID = 24;
export const PNP1_USER_ROLE_ID = 25;
export const PNP2_USER_ROLE_ID = 26;
export const PNP3_USER_ROLE_ID = 27;

export const PNF_USER_ROLE_ID = 8;
export const PNM1_USER_ROLE_ID = 9;
export const PNM2_USER_ROLE_ID = 10;
export const PNM3_USER_ROLE_ID = 11;
export const PNS_USER_ROLE_ID = 12;
export const FF_USER_ROLE_ID = 13;

export const FRANCHISE_OR_HIGHER_USER_ROLES = [
  PNF_USER_ROLE_ID,
  PNM1_USER_ROLE_ID,
  PNM2_USER_ROLE_ID,
  PNM3_USER_ROLE_ID,
  PNS_USER_ROLE_ID,
  FF_USER_ROLE_ID
];

export const COMMON_USER_ROLES = [
  CUSTOMER_USER_ROLE_ID,
  PNH_USER_ROLE_ID,
  PNP_USER_ROLE_ID,
  EXTERNAL_USER_ROLE_ID,
  PNC_USER_ROLE_ID,
  PNP1_USER_ROLE_ID,
  PNP2_USER_ROLE_ID,
  PNP3_USER_ROLE_ID
];

export function isNotCommonUser(user_role) {
  return user_role && !COMMON_USER_ROLES.includes(user_role);
}

export function isFranchiseOrHigherByUserRole(user_role) {
  return user_role && FRANCHISE_OR_HIGHER_USER_ROLES.includes(user_role);
}
